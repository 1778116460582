









import { Component, Vue, Watch } from 'vue-property-decorator'
import memberInfo from '@/components/member/info.vue'
import { Getter, Action } from "vuex-class"

@Component({
  components: {
    memberInfo
  }
})
export default class MemberDetailInfo extends Vue {
  @Action('usersDetail') usersDetail: Function
  @Getter('detailInfo') detailInfo: Object
  project: any = {}
  get formData (): any[] {
    let arr: any[] = []
    arr.push(this.detailInfo)
    return arr
  }
  loading: boolean = false
  mounted() {
    this.project = JSON.parse(localStorage.getItem("projects"))
    this.doDetail()
  }
  doDetail () {
    this.loading = true
    let params = {
      id: this.$route.params.id,
      projectId : this.project.id
    }
    this.usersDetail({ params }).then(() => {
      setTimeout(() => {
        this.loading = false
      }, 200)
    })
  }
}
