













































































import { Component, Vue, Watch, Prop } from "vue-property-decorator"
import { Getter, Action } from "vuex-class"
import globalMixins from '@/mixins/global.ts'

@Component({
  mixins: [globalMixins]
})
export default class Info extends Vue<globalMixins> {
  @Action('usersUpdate') usersUpdate: Function
  @Action('projectGroups') projectGroups: Function
  @Getter('projGroups') projGroups: Object[]
  @Getter('asideConfigs') asideConfigs: Object

  @Prop()
  formData: any[]

  formInfo: Object = {}
  project: any = {}
  editing: boolean = false
  groupName: string = ''
  created () {
    this.project = JSON.parse(localStorage.getItem("projects"))
  }
  get isLeader () {
    return (this.project as any).role === 'leader'
  }
  // ideal不展示
  get isUserGroup () {
    return (this.asideConfigs as any).user_group
  }
  getProjGroups () {
    let params = {
      projectId: this.project.id
    }
    this.projectGroups({ params }).then(() => {
      this.groupTrans()
    })
  }
  groupTrans () {
    let id = (this.formInfo as any).group_id
    if (id) {
      let group: any = this.projGroups.find((item: any) => {
        return item.id === id
      })
      this.groupName = group.name
    }
  }
  sexChange (type) {
    if (type === 'male') {
      return '男'
    } else {
      return '女'
    }
  }
  edit () {
    this.editing = true
  }
  cancel () {
    this.editing = false
  }
  save () {
    let memberFormInfo = this.$refs.formInfo as HTMLFormElement
    memberFormInfo.validate((valid) => {
      if (valid) {
        let params = Object.assign({}, this.formInfo, {projectId: this.project.id})
        this.usersUpdate({ params }).then(() => {
          this.successMsg('修改成功!')
            this.editing = false
            this.$emit('updateMemberInfo')
        })
      } else {
        return false
      }
    })
  }
  @Watch('formData')
  onDetailInfoChange (val: any[], old: any[]) {
    this.formInfo = Object.assign({}, val[0])
    // 避免前端展示分组时为0
    if ((this.formInfo as any).group_id === 0) {
      (this.formInfo as any).group_id = null
    }
    this.isLeader && this.isUserGroup && this.getProjGroups()
  }
}
